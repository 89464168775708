import PrimaryButton from "components/buttons/primary";
import CompanyEventCard from "components/company-volunteer-event";
import ControlledSelect from "components/controlled-select";
import ControlledCustomMultiselect from "components/custom-multiselect-component";
import ControlledCustomSelect from "components/custom-select-component";
import FixedHeader from "components/fixed-header";
import StateAutocompleteInput from "components/state-autocomplete-input";
import VolunteerEventCard from "components/volunteer-event-card";
import React from "react";
import { useForm } from "react-hook-form";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useSearchParams } from "react-router-dom";
import companySelectors from "store/company/company.selector";
import { fetchActiveCompanyVolunteerEvents } from "store/company-active-volunteer-events/company-active-volunteer-events.action";
import companyActiveVolunteerEventsSelectors from "store/company-active-volunteer-events/company-active-volunteer-events.selector";
import { fetchPastCompanyVolunteerEvents } from "store/company-past-volunteer-events/company-past-volunteer-events.action";
import companyPastVolunteerEventsSelectors from "store/company-past-volunteer-events/company-past-volunteer-events.selector";
import { fetchPendingCompanyVolunteerEvents } from "store/company-pending-volunteer-events/company-pending-volunteer-events.action";
import companyPendingVolunteerEventsSelectors from "store/company-pending-volunteer-events/company-pending-volunteer-events.selector";
import {
  fetchVolunteerEvents,
  refreshVolunteerEvents,
  setVolunteerEventFilters,
} from "store/company-volunteer-events/company-volunteer-events.action";
import volunteerSelectors from "store/company-volunteer-events/company-volunteer-events.selector";
import { updateDialogState } from "store/ui-components/ui-components.actions";
import { GRANT_OPTIONS, VolunteerEventItem, VolunteerTable } from "types/volunteer";
import {
  EVENT_LOCATION_MAPPER,
  GRANT_REQUIRED,
  REVERSE_EVENT_LOCATION_MAPPER,
  VOLUNTEER_EVENT_PLACES_LIST,
  VOLUNTEER_NUMBER,
  VOLUNTEER_NUMBER_FOR_FILTER,
  VOLUNTEERING_TABS,
} from "utils/content";
import { genRandomString } from "utils/helper";
import { getAbbreviation } from "utils/state-codes";

import * as S from "./volunteering.styled";

const VolunteeringPage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedTable, setSelectedTable] = React.useState<VolunteerTable>(
    (searchParams.get("view") as VolunteerTable) || "events",
  );

  const isFetched = useSelector(volunteerSelectors.selectIsFetched);
  const inProgress = useSelector(volunteerSelectors.selectInProgress);
  const eventsData = useSelector(volunteerSelectors.selectData);
  const eventsPage = useSelector(volunteerSelectors.selectPage);
  const eventsEndReached = useSelector(volunteerSelectors.selectEndReached);
  const filters = useSelector(volunteerSelectors.selectFilters);
  const eventsResults = useSelector(volunteerSelectors.selectResults);

  const isActiveFetched = useSelector(companyActiveVolunteerEventsSelectors.selectIsFetched);
  const activeInProgress = useSelector(companyActiveVolunteerEventsSelectors.selectInProgress);
  const activeEventsData = useSelector(companyActiveVolunteerEventsSelectors.selectData);
  const activeEventsPage = useSelector(companyActiveVolunteerEventsSelectors.selectPage);
  const activeEventsEndReached = useSelector(
    companyActiveVolunteerEventsSelectors.selectEndReached,
  );

  const isPendingFetched = useSelector(companyPendingVolunteerEventsSelectors.selectIsFetched);
  const pendingInProgress = useSelector(companyPendingVolunteerEventsSelectors.selectInProgress);
  const pendingEventsData = useSelector(companyPendingVolunteerEventsSelectors.selectData);
  const pendingEventsPage = useSelector(companyPendingVolunteerEventsSelectors.selectPage);
  const pendingEventsEndReached = useSelector(
    companyPendingVolunteerEventsSelectors.selectEndReached,
  );

  const isPastFetched = useSelector(companyPastVolunteerEventsSelectors.selectIsFetched);
  const pastInProgress = useSelector(companyPastVolunteerEventsSelectors.selectInProgress);
  const pastEventsData = useSelector(companyPastVolunteerEventsSelectors.selectData);
  const pastEventsPage = useSelector(companyPastVolunteerEventsSelectors.selectPage);
  const pastEventsEndReached = useSelector(companyPastVolunteerEventsSelectors.selectEndReached);

  const containerRef = React.useRef();
  const stateInputRef = React.useRef();

  const isTablet = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const companyName = useSelector(companySelectors.selectName);

  const { setValue, getValues, control } = useForm({
    defaultValues: {
      grant:
        filters.grant === null
          ? null
          : filters.grant.toString() === "true"
          ? GRANT_REQUIRED.find((item) => item.value.includes("true")) || null
          : GRANT_REQUIRED.find((item) => item.value.includes("false")) || null,
      groupSize:
        filters.groupSize.length > 0
          ? filters.groupSize.map((groupSize) =>
              VOLUNTEER_NUMBER.find((item) => item.value === groupSize),
            )
          : [] || [],
      location:
        filters.location.length > 0
          ? filters.location.map((location) =>
              VOLUNTEER_EVENT_PLACES_LIST.find(
                (item) => item.value === REVERSE_EVENT_LOCATION_MAPPER[location],
              ),
            )
          : [] || [],
      state: "",
    },
    mode: "onTouched",
  });

  const selectItems = VOLUNTEERING_TABS.map((item) => ({
    id: genRandomString(),
    text: item.text,
    value: item.table,
  }));

  React.useEffect(() => {
    setSelectedTable((searchParams.get("view") as VolunteerTable) || "events");
  }, [searchParams]);

  React.useEffect(() => {
    if (!isFetched) {
      dispatch(fetchVolunteerEvents.request({ page: 1 }));
    }
  }, [isFetched]);

  React.useEffect(() => {
    if (!isActiveFetched) {
      dispatch(
        fetchActiveCompanyVolunteerEvents.request({ params: { page: 1 }, status: "ACTIVE" }),
      );
    }
  }, [isActiveFetched]);

  React.useEffect(() => {
    if (!isPendingFetched) {
      dispatch(
        fetchPendingCompanyVolunteerEvents.request({ params: { page: 1 }, status: "PENDING" }),
      );
    }
  }, [isPendingFetched]);

  React.useEffect(() => {
    if (!isPastFetched) {
      dispatch(
        fetchPastCompanyVolunteerEvents.request({ params: { page: 1 }, status: "ACTIVE/PAST" }),
      );
    }
  }, [isPastFetched]);

  const handleLoadMore = (): void => {
    if (!inProgress) {
      const { grant, groupSize, location, state } = getValues();
      if (grant !== null || groupSize.length > 0 || location?.length > 0 || state !== "") {
        const grantValue = grant === null ? null : (grant?.value as GRANT_OPTIONS);
        const groupSizeValues = groupSize?.map((item) => item.value);
        const locationValues =
          location?.map((item) => item.value).length > 0 ? location?.map((item) => item.value) : [];
        const mappedLocationValue = locationValues?.map((value) => EVENT_LOCATION_MAPPER[value]);
        const stateValue = getAbbreviation(state) || null;

        const allFilters = {
          grant: grantValue,
          groupSize: groupSizeValues,
          location: mappedLocationValue,
          ...(stateValue && { state: stateValue }),
        };

        dispatch(setVolunteerEventFilters(allFilters));

        const refreshPayload = {
          page: eventsPage + 1,
          grant: grantValue === null ? null : grantValue,
          groupSize: groupSizeValues,
          location: mappedLocationValue,
          ...(stateValue && { state: stateValue }),
        };

        dispatch(fetchVolunteerEvents.request(refreshPayload));
      } else {
        dispatch(fetchVolunteerEvents.request({ page: eventsPage + 1 }));
      }
    }
  };

  const handleLoadMoreActiveEvents = (): void => {
    if (!activeInProgress) {
      dispatch(
        fetchActiveCompanyVolunteerEvents.request({
          params: { page: activeEventsPage + 1 },
          status: "ACTIVE",
        }),
      );
    }
  };

  const handleLoadMorePendingEvents = (): void => {
    if (!pendingInProgress) {
      dispatch(
        fetchPendingCompanyVolunteerEvents.request({
          params: { page: pendingEventsPage + 1 },
          status: "PENDING",
        }),
      );
    }
  };

  const handleLoadMorePastEvents = (): void => {
    if (!pastInProgress) {
      dispatch(
        fetchPastCompanyVolunteerEvents.request({
          params: { page: pastEventsPage + 1 },
          status: "ACTIVE/PAST",
        }),
      );
    }
  };

  const isFilterActive = (): boolean => {
    const { grant, groupSize, location } = getValues();
    return grant !== null || groupSize.length > 0 || location?.length > 0;
  };

  const handleResetFilters = (): void => {
    setValue("grant", null);
    setValue("groupSize", []);
    setValue("location", []);
    setValue("state", "");

    // Re-fetch data after resetting filters
    dispatch(refreshVolunteerEvents.request({ page: 1 }));
    dispatch(
      setVolunteerEventFilters({
        grant: null,
        groupSize: [],
        location: [],
        state: "",
      }),
    );
  };

  const handleFilterSubmit = (): void => {
    const { grant, groupSize, location, state } = getValues();

    if (grant !== null || groupSize.length > 0 || location?.length > 0 || state !== "") {
      const grantValue = grant === null ? null : (grant?.value as GRANT_OPTIONS);
      const groupSizeValues = groupSize?.map((item) => item.value);
      const locationValues =
        location?.map((item) => item.value).length > 0 ? location?.map((item) => item.value) : [];
      const mappedLocationValue = locationValues?.map((value) => EVENT_LOCATION_MAPPER[value]);
      const stateValue = getAbbreviation(state) || null;

      const allFilters = {
        grant: grantValue,
        groupSize: groupSizeValues,
        location: mappedLocationValue,
        ...(stateValue && { state: stateValue }),
      };

      dispatch(setVolunteerEventFilters(allFilters));

      const refreshPayload = {
        page: 1,
        grant: grantValue === null ? null : grantValue,
        groupSize: groupSizeValues,
        location: mappedLocationValue,
        ...(stateValue && { state: stateValue }),
      };

      dispatch(refreshVolunteerEvents.request(refreshPayload));
    }
  };

  return (
    <S.VolunteeringPageWrapper
      alignItems="flex-start"
      justifyContent="flex-start"
      ref={containerRef}
      isEmptyView={eventsData.length <= 0}
    >
      <FixedHeader name={companyName} />

      <S.PagePaddingContainer>
        <S.Separator height={53} />
        <S.VolunteeringHeading>Volunteering</S.VolunteeringHeading>
        <S.Separator height={44} />

        {isTablet ? (
          <>
            <ControlledSelect
              items={selectItems}
              name="table"
              sx={{ width: "100%", maxWidth: "620px", height: "20px", alignSelf: "start" }}
              onSelect={(val) => setSelectedTable(val as VolunteerTable)}
            />
            <S.Separator height={30} />
          </>
        ) : (
          <S.RowDisplay width="100%" justifyContent="flex-start" style={{ overflow: "auto" }}>
            {VOLUNTEERING_TABS.map((item, i) => (
              <S.TabSwitch
                key={genRandomString()}
                isSelected={item.table === selectedTable}
                isFirst={i === 0}
                isLast={i === VOLUNTEERING_TABS.length - 1}
                onClick={() => setSearchParams({ view: item.table })}
              >
                <S.TabSwitchText isSelected={item.table === selectedTable}>
                  {item.text}
                </S.TabSwitchText>
              </S.TabSwitch>
            ))}
          </S.RowDisplay>
        )}
      </S.PagePaddingContainer>
      <S.Separator height={35} />

      {selectedTable === "events" && (
        <S.WhiteBgContainer>
          <S.Separator height={35} />
          <S.SearchAndFilterMainContainer>
            <S.SearchAndFilterContainer>
              <S.SearchAndFilterInnerContainer
                placeholder="Select Location"
                justifyContent="flex-start"
                gridGap={15}
                flexWrap
              >
                <StateAutocompleteInput
                  topLabel="State"
                  placeholder="State"
                  name="state"
                  control={control}
                  ref={stateInputRef}
                />
                <ControlledCustomSelect
                  name="grant"
                  control={control}
                  items={GRANT_REQUIRED}
                  placeholder="Grant Requirement"
                />
                <ControlledCustomMultiselect
                  name="groupSize"
                  control={control}
                  items={VOLUNTEER_NUMBER_FOR_FILTER}
                  placeholder="Group size"
                />
                <ControlledCustomMultiselect
                  name="location"
                  control={control}
                  items={VOLUNTEER_EVENT_PLACES_LIST}
                  placeholder="Event Type"
                />
                <PrimaryButton
                  label="Search"
                  onClick={handleFilterSubmit}
                  sx={{ borderRadius: 6, maxWidth: "191px" }}
                />
                <S.ResetFilters isDisabled={false} onClick={() => handleResetFilters()}>
                  Reset filters
                </S.ResetFilters>
              </S.SearchAndFilterInnerContainer>
            </S.SearchAndFilterContainer>
          </S.SearchAndFilterMainContainer>
          {isFilterActive() && (
            <S.MatchesNumber>
              {eventsResults > 0
                ? eventsResults === 1
                  ? "1 charity matches"
                  : `${eventsResults} events match`
                : `${eventsResults} events match`}
            </S.MatchesNumber>
          )}
          {eventsData.length > 0 ? (
            <InfiniteScroll
              pageStart={eventsPage || 1}
              loadMore={handleLoadMore}
              hasMore={!eventsEndReached && !inProgress}
              useWindow={false}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              getScrollParent={() => containerRef.current}
            >
              <S.VolonteerEventContainer
                flexWrap
                gridGap={30}
                width={eventsData.length <= 0 ? "100%" : undefined}
                justifyContent="flex-start"
              >
                {eventsData.map((event: VolunteerEventItem) => (
                  <CompanyEventCard key={event.id} {...{ ...event }} />
                ))}
              </S.VolonteerEventContainer>
            </InfiniteScroll>
          ) : (
            <div style={{ flex: 1 }}>
              <S.EmptyViewText>No items to display.</S.EmptyViewText>
            </div>
          )}
        </S.WhiteBgContainer>
      )}
      {selectedTable === "pending" && (
        <S.WhiteBgContainer>
          <S.Separator height={35} />
          {pendingEventsData.length > 0 ? (
            <InfiniteScroll
              pageStart={pendingEventsPage || 1}
              loadMore={handleLoadMorePendingEvents}
              hasMore={!pendingEventsEndReached && !inProgress}
              useWindow={false}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              getScrollParent={() => containerRef.current}
            >
              <S.VolonteerEventContainer
                flexWrap
                gridGap={30}
                width={pendingEventsData.length <= 0 ? "100%" : undefined}
                justifyContent="flex-start"
              >
                {pendingEventsData.map((event: VolunteerEventItem) => (
                  <CompanyEventCard key={event.id} {...{ ...event }} />
                ))}
              </S.VolonteerEventContainer>
            </InfiniteScroll>
          ) : (
            <S.EmptyViewText>No items to display.</S.EmptyViewText>
          )}
        </S.WhiteBgContainer>
      )}
      {selectedTable === "active" && (
        <S.WhiteBgContainer>
          <S.Separator height={35} />
          <PrimaryButton
            label="Post Event"
            onClick={() =>
              dispatch(
                updateDialogState({ key: "POST_CUSTOM_VOLUNTEER_EVENT_STEP_1", isOpened: true }),
              )
            }
            sx={{ margin: "0 150px 44px 150px", height: 40, maxWidth: 180 }}
          />
          <S.ActiveEventsCount>
            Current Active Events: {activeEventsData.length}
          </S.ActiveEventsCount>
          {activeEventsData.length > 0 ? (
            <InfiniteScroll
              pageStart={activeEventsPage || 1}
              loadMore={handleLoadMoreActiveEvents}
              hasMore={!activeEventsEndReached && !activeInProgress}
              useWindow={false}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              getScrollParent={() => containerRef.current}
            >
              <S.VolonteerEventContainer
                flexWrap
                gridGap={30}
                width={activeEventsData.length <= 0 ? "100%" : undefined}
                justifyContent="flex-start"
              >
                {activeEventsData.map((event: VolunteerEventItem) => (
                  <VolunteerEventCard
                    {...{ ...(event as VolunteerEventItem) }}
                    key={genRandomString()}
                    isAdminView
                  />
                ))}
              </S.VolonteerEventContainer>
            </InfiniteScroll>
          ) : (
            <S.EmptyViewText>No items to display.</S.EmptyViewText>
          )}
        </S.WhiteBgContainer>
      )}

      {selectedTable === "past" && (
        <S.WhiteBgContainer>
          <S.Separator height={35} />
          {pastEventsData.length > 0 ? (
            <InfiniteScroll
              pageStart={pastEventsPage || 1}
              loadMore={handleLoadMorePastEvents}
              hasMore={!pastEventsEndReached && !pastInProgress}
              useWindow={false}
              loader={
                <div className="loader" key={0}>
                  Loading ...
                </div>
              }
              getScrollParent={() => containerRef.current}
            >
              <S.VolonteerEventContainer
                flexWrap
                gridGap={30}
                width={pastEventsData.length <= 0 ? "100%" : undefined}
                justifyContent="flex-start"
              >
                {pastEventsData.map((event: VolunteerEventItem) => (
                  <CompanyEventCard key={event.id} {...{ ...event }} />
                ))}
              </S.VolonteerEventContainer>
            </InfiniteScroll>
          ) : (
            <S.EmptyViewText>No items to display.</S.EmptyViewText>
          )}
        </S.WhiteBgContainer>
      )}
    </S.VolunteeringPageWrapper>
  );
};

export default VolunteeringPage;
