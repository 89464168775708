import { Spinner } from "@primer/react";
import { WINDOW } from "@sentry/react";
import { useFinchConnect } from "@tryfinch/react-connect";
import PrimaryButton from "components/buttons/primary";
import FixedHeader from "components/fixed-header";
import { EmployeesGreenIcon, EmployeesPurpleIcon, FundwurxLogoIcon } from "icons";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import companySelectors from "store/company/company.selector";
import { initializeFinch } from "store/finch/finch.action";
import {
  getAdminUsers,
  getEmployeeUsers,
  getUserStatistics,
} from "store/manage-people/manage-people.action";
import managePeopleSelectors from "store/manage-people/manage-people.selector";
import { IAppState } from "store/store.state";
import { showToast, updateDialogState } from "store/ui-components/ui-components.actions";
import colors from "theme/colors";
import { ManagePeopleTableType } from "types/volunteer";
import { IS_PROD, MAX_TABLE_COLUMNS } from "utils/constants";
import { MANAGE_PEOPLE_TABS } from "utils/content";
import { genRandomString, uriEncode } from "utils/helper";
import routes from "utils/routes";

import AnalyticDisplay from "./components/analytic-display";
import ManagePeopleTable from "./components/table-display";
import * as S from "./manage-people.styled";

const clientId = window.__RUNTIME_CONFIG__.FINCH_CLIENT_ID;

const ManagePeoplePage: React.FC<{}> = (): JSX.Element => {
  const dispatch = useDispatch();

  const [selectedTable, setSelectedTable] = React.useState<ManagePeopleTableType>("admins");

  const companyName = useSelector(companySelectors.selectName);
  const employees = useSelector((state: IAppState) =>
    managePeopleSelectors.selectData(state, "employees"),
  );
  const admins = useSelector((state: IAppState) =>
    managePeopleSelectors.selectData(state, "admins"),
  );

  const adminsPage = useSelector((state: IAppState) =>
    managePeopleSelectors.selectTablePage(state, "admins"),
  );
  const employeesPage = useSelector((state: IAppState) =>
    managePeopleSelectors.selectTablePage(state, "employees"),
  );

  const isFinchKeyAdded = useSelector(companySelectors.selectFinchKeyAdded);

  const numAdmins = useSelector(managePeopleSelectors.selectNumAdmins);
  const numEmployees = useSelector(managePeopleSelectors.selectNumEmployees);
  const isFetched = useSelector(managePeopleSelectors.selectIsFetched);
  const statistics = useSelector(managePeopleSelectors.selectStatistics);

  const onFinchConnectSuccess = ({ code }) => {
    dispatch(initializeFinch.request(code));
  };

  const onFinchError = ({ errorMessage }) =>
    dispatch(showToast({ message: errorMessage, type: "failure" }));

  const onFinchClose = () => {};

  const isMobile = useMediaQuery({
    query: `(max-width: ${S.TABLET_BREAKPOINT}px)`,
  });

  const { open } = useFinchConnect(
    IS_PROD
      ? {
          clientId,
          products: ["company", "directory", "individual", "employment"],
          sandbox: false,
          onSuccess: onFinchConnectSuccess,
          onError: onFinchError,
          onClose: onFinchClose,
        }
      : {
          clientId,
          products: ["company", "directory", "individual", "employment"],
          sandbox: "finch",
          onSuccess: onFinchConnectSuccess,
          onError: onFinchError,
          onClose: onFinchClose,
        },
  );

  React.useEffect(() => {
    if (!isFetched) {
      dispatch(getAdminUsers.request({}));
      dispatch(getEmployeeUsers.request({}));
    }
  }, []);

  React.useEffect(() => {
    if (!statistics.isFetched) {
      dispatch(getUserStatistics.request());
    }
  }, []);

  const copyInviteLink = () => {
    navigator.clipboard.writeText(
      `${WINDOW.__RUNTIME_CONFIG__.REACT_APP_URL}${uriEncode(companyName)}${
        routes.EMPLOYEE.REGISTER
      }`,
    );
    dispatch(showToast({ message: "Link copied to clipboard!", type: "success" }));
  };

  return (
    <S.ManagePeoplePageWrapper alignItems="flex-start" justifyContent="flex-start">
      <FixedHeader name={companyName} />
      <S.Separator height={53} />
      {!isFetched ? (
        <S.ColumnDisplay style={{ width: "100%", height: "60%" }}>
          <Spinner />
        </S.ColumnDisplay>
      ) : (
        <>
          <S.WhiteBgContainer>
            <S.PaddingContainer>
              <S.GradientHeadingExtended>Manage People</S.GradientHeadingExtended>
              <PrimaryButton
                label="Connect HR System"
                isDisabled={isFinchKeyAdded}
                onClick={open}
                sx={{ width: 201, height: 44, fontWeight: "600" }}
              />
            </S.PaddingContainer>
            <S.PaddingContainer>
              <S.LineSeparator />
            </S.PaddingContainer>
            <S.PaddingContainer>
              <S.Paragraph>Signup & Usage Analytics</S.Paragraph>
            </S.PaddingContainer>
            <S.AnalyticPaddingContainer>
              <AnalyticDisplay
                paragraph="Total Employees Invited"
                color={colors.lightGreen()}
                number={statistics.totalUsers}
              >
                <EmployeesGreenIcon />
              </AnalyticDisplay>
              <AnalyticDisplay
                paragraph="Total Employees Registered"
                color={colors.lightPurple()}
                number={statistics.totalVerifiedUsers}
                percent={`${statistics.totalVerifiedPercentage}%`}
                percentBackgroundColor={colors.lightPurple(0.2)}
              >
                <EmployeesPurpleIcon />
              </AnalyticDisplay>
              <AnalyticDisplay
                paragraph="Total Employees Who Used Fundwurx"
                number={statistics.totalInteractedUsers}
                percent={`${statistics.totalInteractedPercentage}%`}
              >
                <FundwurxLogoIcon />
              </AnalyticDisplay>
            </S.AnalyticPaddingContainer>
            <S.PaddingContainer>
              <S.LineSeparator />
            </S.PaddingContainer>
          </S.WhiteBgContainer>
          <S.RowDisplay
            width="100%"
            flexWrap
            gridGap={20}
            justifyContent="space-between"
            style={{ overflow: "auto" }}
          >
            <S.RowDisplay alignItems="center" justifyContent="flex-start" gridGap={0}>
              {MANAGE_PEOPLE_TABS.map((item, i) => (
                <S.TabSwitch
                  key={genRandomString()}
                  isSelected={item.table === selectedTable}
                  isFirst={i === 0}
                  isLast={i === MANAGE_PEOPLE_TABS.length - 1}
                  onClick={() => setSelectedTable(item.table)}
                >
                  <S.TabSwitchText isSelected={item.table === selectedTable}>
                    {item.text}
                  </S.TabSwitchText>
                </S.TabSwitch>
              ))}
            </S.RowDisplay>

            {selectedTable === "admins" && (
              <S.RowDisplay alignItems="center" justifyContent="flex-start" gridGap={40}>
                <PrimaryButton
                  label="Invite Admin"
                  onClick={() =>
                    dispatch(updateDialogState({ key: "INVITE_ADMIN", isOpened: true }))
                  }
                  sx={{ width: 173, height: 44 }}
                />
              </S.RowDisplay>
            )}
            {selectedTable === "employees" && (
              <S.RowDisplay
                width={isMobile ? "100%" : undefined}
                alignItems="center"
                flexWrap
                justifyContent="flex-start"
                gridGap={10}
              >
                <PrimaryButton
                  label="Copy Registration Link"
                  onClick={copyInviteLink}
                  sx={{ width: 201, height: 44, fontWeight: "600" }}
                />
                <PrimaryButton
                  label="Invite Employee"
                  onClick={() =>
                    dispatch(updateDialogState({ key: "ADD_EMPLOYEE", isOpened: true }))
                  }
                  sx={{ width: 201, height: 44 }}
                />
              </S.RowDisplay>
            )}
          </S.RowDisplay>
          {selectedTable === "admins" && (
            <ManagePeopleTable
              manageRole="admins"
              items={admins}
              totalItemsNum={numAdmins}
              currentPage={adminsPage}
              displayPagination={numAdmins > MAX_TABLE_COLUMNS}
            />
          )}

          {selectedTable === "employees" && (
            <ManagePeopleTable
              manageRole="employees"
              items={employees}
              totalItemsNum={numEmployees}
              currentPage={employeesPage}
              displayPagination={numEmployees > MAX_TABLE_COLUMNS}
            />
          )}
          <S.Separator height={20} />
        </>
      )}
    </S.ManagePeoplePageWrapper>
  );
};

export default ManagePeoplePage;
