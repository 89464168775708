import unGoalsImg from "assets/images/un-goals.png";
import styled from "styled-components";
import { ColumnDisplay, GradientHeading, RowDisplay } from "theme/global-style";
import breakpoints from "utils/breakpoints";

export {
  BubbleBackground,
  ClickableWrapper,
  ColumnDisplay,
  FormWrapper,
  LinkWrapper,
  RowDisplay,
  Separator,
} from "theme/global-style";

export const TABLET_BREAKPOINT = breakpoints.desktopS.x;
export const MOBILE_BREAKPOINT = breakpoints.iPhone13Pro.x;

export const VolunteerEventViewContainer = styled(ColumnDisplay)`
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: scroll;
  background: ${({ theme }) => theme.aliceBlue()};
`;

export const VolunteerEventViewInnerContainer = styled(ColumnDisplay)`
  width: calc(100vw - 2 * 152px);
  height: 100%;
  max-width: 1424px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: calc(100% - 2 * 24px);
  }
`;

export const RowDisplayFullWidth = styled(RowDisplay)`
  width: 100%;
  flex-wrap: wrap;
`;

export const DetailsText = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const GradientHeadingExtended = styled(GradientHeading)`
  margin: 0px;
  text-align: start;
`;

export const ContentWrapper = styled(ColumnDisplay)`
  flex-basis: 1;
`;

export const FormsContainer = styled(RowDisplay)`
  align-items: flex-start;
  flex-basis: 1;
  height: min-content;
  width: calc(100vw - 2 * 200px);
  max-width: 1495px;
  min-width: 600px;
  position: relative;

  @media all and (max-width: ${breakpoints.desktopS.x}px) {
    flex-direction: column;
    align-items: flex-start;
    min-width: 400px;
    width: calc(100vw - 2 * 30px);
  }
`;

export const BottomContentContainer = styled(ColumnDisplay)`
  flex: 1;
  width: calc(100vw - 2 * 200px);
  max-width: 1495px;
  min-width: 600px;
  position: relative;
  box-sizing: border-box;

  @media all and (max-width: ${breakpoints.desktopS.x}px) {
    min-width: 400px;
    width: calc(100vw - 2 * 30px);
  }
`;

export const BottomFormContainer = styled(RowDisplay)`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);
  flex: 1;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  padding: 30px 30px 38px 37px;
  border-radius: 12px;

  @media all and (max-width: ${breakpoints.desktopS.x}px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`;

export const BottomFormItem = styled(ColumnDisplay)<{ width?: string; maxWidth?: string }>`
  width: ${({ width = "auto" }) => width};
  max-width: ${({ maxWidth = "auto" }) => maxWidth};
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  @media all and (max-width: ${breakpoints.desktopS.x}px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const LeftContentContainer = styled(ColumnDisplay)`
  flex-grow: 1;
  width: calc(100% - 475px);
  height: 100%;
  box-sizing: border-box;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    height: min-content;
    max-width: calc(100vw - 2 * 24px);
  }
`;

export const MissionStatementContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);
  border-radius: 12px;
  flex: 1;
  width: 100%;
  box-sizing: border-box;
  padding: 30px 30px 38px 37px;
  overflow-x: hidden;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    max-width: 100%;
  }

  @media all and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 16px;
  }
`;

export const VolunteerEventImg = styled.img`
  width: 72px;
  max-width: 72px;
  min-width: 72px;
  height: auto;
  object-fit: cover;
`;

export const MissionStatementHeading = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-transform: uppercase;
  color: ${({ theme }) => theme.unitedNationsBlue()};
`;

export const MissionStatementParagraph = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  word-break: break-word;
  color: ${({ theme }) => theme.gunmetal()};
`;

export const ShowMoreLink = styled(RowDisplayFullWidth)`
  color: ${({ theme }) => theme.unitedNationsBlue()};
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  cursor: pointer;
`;

export const MissionStatementBottomDisplay = styled(RowDisplay)`
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    flex-direction: column;
  }
`;

export const UNGoalContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  padding: 16px;
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);
  border-radius: 12px;
  box-sizing: border-box;
  width: 100%;
  max-width: 450px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
  }
`;

export const UNGoalText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.unitedNationsBlue()};
`;

export const VolunteerEventInfoContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);
  border-radius: 12px;
  width: 427px;
  height: 100%;
  box-sizing: border-box;
  padding: 30px;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    min-width: 310px;
    height: auto;
    width: 100%;
  }
`;

export const VolunteerEventInfoText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  max-width: calc(100% - 30px);
  color: ${({ theme }) => theme.gunmetal()};
  word-break: break-word;
`;

export const VolunteerEventInfoTextBold = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  font-weight: 500;
  max-width: calc(100% - 30px);
  word-break: break-word;
`;

export const VolunteerEventInfoSeparator = styled.div`
  width: calc(100% + 57px);
  margin-left: -30px;
  height: 1px;
  background: ${({ theme }) => theme.gunmetal(0.1)};
`;

export const UNGoalsImg = styled.div`
  width: 63px;
  height: 36px;
  background-image: url(${unGoalsImg});
`;

export const DonationText = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  word-break: break-word;
  color: ${({ theme }) => theme.black(0.4)};
`;

export const DonationTextBold = styled.span`
  font-weight: 600;
`;

export const VolunteerEventDetailsText = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: ${({ theme }) => theme.unitedNationsBlue()};
  text-transform: uppercase;
`;

export const EmptyContainer = styled(ColumnDisplay)`
  background: ${({ theme }) => theme.white()};
  box-shadow: 0px 8px 24px rgba(66, 74, 83, 0.12), 0px 1px 3px rgba(27, 31, 36, 0.12);
  border-radius: 12px;
  flex-shrink: 1;
  max-width: 940px;
  width: 100%;
  height: 420px;
  align-self: center;
  box-sizing: border-box;
  padding: 30px 30px 38px 37px;
  overflow: hidden;

  @media all and (max-width: ${TABLET_BREAKPOINT}px) {
    width: 100%;
    max-width: 100%;
  }

  @media all and (max-width: ${MOBILE_BREAKPOINT}px) {
    padding: 16px;
  }
`;

export const EmptyHeading = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.spaceCodet()};
`;

export const EmptySubheading = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: center;
  color: ${({ theme }) => theme.blackCoral()};
`;

export const LocationContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
`;

export const EventFullBanner = styled.div`
  background: ${({ theme }) => theme.lightRed()};
  max-width: 108px;
  max-height: 34px;
  border-radius: 45px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  color: ${({ theme }) => theme.warningRed()};
  font-size: 14px;
  line-height: 20px;
  font-weight: 600;
`;

export const ToggleContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-bottom: 20px;
`;

export const ToggleButton = styled.div<{ isSelected: boolean }>`
  flex: 1;
  padding: 10px 20px;
  text-align: center;
  font-weight: 400;
  background: ${({ theme, isSelected }) =>
    isSelected ? theme.unitedNationsBlue() : theme.white()};
  border: ${({ theme, isSelected }) => (isSelected ? "none" : `1px solid ${theme.mantee()}`)};
  color: ${({ theme, isSelected }) => (isSelected ? theme.white() : theme.gunmetal())};
  transition: background-color 0.3s;
  flex-basis: auto;
  whitespace: nowrap;

  &:first-child {
    border-radius: 8px 0 0 8px;
  }

  &:last-child {
    border-radius: 0 8px 8px 0;
  }
`;
